<template>
    <section v-if="this.getIsSocialNetwork" class="socialNetworkHeader">
        <div class="fr-container--fluid">
            <div class="fr-grid-row fr-grid-row--gutters fr-px-3w fr-pt-3w fr-mb-4w">
                <div class="fr-col-12 fr-col-lg-9 socialNetworkHeader__titleSection">
                    <div>
                        <h1 id="frequentation-social-network" class="fr-mb-0">
                            Données des réseaux sociaux de l'État
                        </h1>
                    </div>
                </div>
                <div class="fr-col-12 fr-col-lg-3 socialNetworkHeader__btn">
                    <button id="fr-btn-social-network-filtrer" class="fr-btn fr-mx-1v socialNetworkHeader__btn--filter"
                        @click="openSocialNetworkSidenav" aria-expanded=true title="Filtrer">Filtrer
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "SocialNetworkTitleBar",
    computed: {
        ...mapGetters([
            'getIsSocialNetwork'
        ]),
    },
    methods: {
        ...mapActions([
            'setIsSocialNetworkSidenavVisible',
        ]),

        openSocialNetworkSidenav() {
            this.setIsSocialNetworkSidenavVisible(true);
        },
    }
};
</script>

<style scoped lang="scss">
@import "../../styles/components/social-network/socialNetworkTitleBar.scss"
</style>