<template>
  <div>
    <div id="app">
      <app-skiplinks></app-skiplinks>
      <!-- Overlay pour le sidenav -->
      <div v-if="getIsSidenavVisible || getIsSocialNetworkSidenavVisible" class="full-screen-overlay"
        @click="closeSidenav"></div>
      <!-- Composants principaux -->
      <app-header></app-header>
      <app-titleBar></app-titleBar>
      <app-socialNetworkTitleBar />
      <app-modal-comparison></app-modal-comparison>
      <app-sidenav v-show="getIsSidenavVisible" />
      <app-socialNetworkSidenav v-show="getIsSocialNetworkSidenavVisible" />
      <main role="main">
        <router-view />
      </main>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import HeaderDsfr from "./components/Header.vue";
import FooterDsfr from "./components/Footer.vue";
import SkipLinksDsfr from "./components/SkipLinks.vue";
import Sidenav from "./components/Sidenav.vue";
import { mapGetters, mapActions } from "vuex";
import TitleBar from "./components/TitleBar.vue";
import ModalComparison from "./components/modals/ModalComparison.vue";
import SocialNetworkTitleBar from './components/social-network/SocialNetworkTitleBar.vue';
import SocialNetworkSideNav from "./components/social-network/SocialNetworkSideNav.vue";

export default {
  components: {
    "app-sidenav": Sidenav,
    "app-header": HeaderDsfr,
    "app-titleBar": TitleBar,
    "app-footer": FooterDsfr,
    "app-skiplinks": SkipLinksDsfr,
    "app-modal-comparison": ModalComparison,
    "app-socialNetworkTitleBar": SocialNetworkTitleBar,
    "app-socialNetworkSidenav": SocialNetworkSideNav
  },
  computed: {
    ...mapGetters({
      getIsSidenavVisible: "getIsSidenavVisible",
      getIsSocialNetworkSidenavVisible: "getIsSocialNetworkSidenavVisible",
      isSmallScreen: "ui/isSmallScreen",
    }),
    isInternetWebsitesBarometerPage() {
      return this.$route.path === '/visites'
    },
    isSocialNetworksBarometerPage() {
      return this.$route.path === '/reseaux-sociaux'
    }
  },
  watch: {
    $route(to) {
      // Modification dynamique du titre de la page selon la route active
      document.title =
        to.meta.title ||
        "L’Observatoire des données des sites Internet de l’État";
    },
  },
  methods: {
    ...mapActions({
      setIsSidenavVisible: "setIsSidenavVisible",
      setIsSocialNetworkSidenavVisible: "setIsSocialNetworkSidenavVisible",
      watchResize: "ui/watchResize", // Mapping correct de l'action
    }),

    closeSidenav() {
      this.setIsSidenavVisible(false);
      this.setIsSocialNetworkSidenavVisible(false);
    },
  },
  mounted() {
    // Activer la surveillance des redimensionnements à l'initialisation
    this.watchResize();
  },
};
</script>

<style>
@import "styles/global.scss";
</style>