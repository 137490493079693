<template>
    <ul class="fr-btns-group" :id="'btn-group-' + viewport">
        <li>
            <button :id="'fr-btn-connect-' + viewport"
                class="fr-btn fr-btn--tertiary fr-btn--icon-left fr-icon-account-circle-fill fr-text--sm"
                v-if="!isAuthenticated" @click="login" aria-hidden="true" title="Me connecter">
                Me connecter
            </button>
        </li>
        <li>
            <button v-if="isAuthenticated && getIsSuperAdmin" :id="'fr-btn-espace-' + viewport"
                class="fr-btn fr-btn--tertiary fr-btn--icon-left fr-icon-account-circle-fill fr-text--sm" type="button">
                <router-link :to="{ name: 'admin-home' }" target="_self" title="Mon espace">
                    Mon espace
                </router-link>
            </button>
        </li>
        <li>
            <button :id="'fr-btn-disconnect-' + viewport"
                class="fr-btn fr-btn--tertiary fr-btn--icon-left fr-icon-logout-box-r-line fr-text--sm" type="button"
                v-if="isAuthenticated" @click="logout" title="Se déconnecter">
                Se déconnecter
            </button>
        </li>
    </ul>
</template>

<script>
export default {
    name: "AdminConnectButtonDsfr",
    props: {
        viewport: {
            type: String,
            default: "desktop",
        },
        isAuthenticated: {
            type: Boolean,
            required: true
        },
        getIsSuperAdmin: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        login() {
            this.$emit('login');
        },
        logout() {
            this.$emit('logout');
        }
    }
};
</script>

<style scoped>
@import "../styles/components/header.scss";
</style>