<template>
  <div class="fr-container--fluid fr-p-2w sidenav" role="navigation" aria-label="Menu latéral">
    <div class="fr-grid-row">
      <div class="fr-col-12">
        <div class="sidenav__container fr-p-0">
          <h2 class="fr-h6 fr-pb-1v fr-pt-4w" id="fr-sidemenu-title">
            Affiner la recherche
          </h2>
          <button class="sidenav__container--close-btn fr-my-2w">
            <span class="fr-icon-close-line" @click="closeSidenav" aria-hidden="true" title="Fermer"></span>
          </button>
        </div>

        <div class="sidenav__title-line fr-p-0"></div>

        <div class="fr-select-group">
          <fieldset class="sidenav__fieldset fr-px-0 fr-mx-0">
            <label class="fr-label" for="select">
              Ministère de tutelle
            </label>
            <select v-model="selectedMinistry" class="fr-select" id="select" name="select" @change="changeMinistry"
              title="Sélectionner un ministère">
              <option value="undefined" selected disabled hidden>Sélectionner un ministère</option>
              <option v-for="t in ministries" :value="t['value']" :key="t['value']">
                {{ t["label"] }}
              </option>
            </select>
          </fieldset>
        </div>

        <div class="fr-select-group">
          <fieldset class="sidenav__fieldset fr-px-0 fr-mx-0">
            <label class="fr-label" for="select">
              Type de site
            </label>
            <select v-model="selectedTypeSite" class="fr-select" id="select" name="select" @change="changeTypeSite"
              title="Sélectionner un type de site">
              <option value="undefined" selected disabled hidden>Sélectionner un type de site</option>
              <option v-for="t in typeSites" :value="t['value']" :key="t['value']">
                {{ t["label"] }}
              </option>
            </select>
          </fieldset>
        </div>

        <div class="fr-select-group">
          <fieldset class="sidenav__fieldset fr-px-0 fr-mx-0">
            <label class="fr-label" for="select">
              Site
            </label>
            <select v-model="selectedSite" class="fr-select" id="select" name="select" @change="changeSite"
              title="Sélectionner un site">
              <option value="undefined" selected disabled hidden>Sélectionner un site</option>
              <option v-for="t in sites" :value="t['value']" :key="t['value']">
                {{ t["label"] }}
              </option>
            </select>
          </fieldset>
        </div>

        <button class="fr-btn fr-btn--tertiary fr-mt-1w fr-mb-3w fr-btn--icon-right fr-icon-close-circle-line"
          @click="resetSites" title="Réinitialiser" id="fr-btn-tertiary-reinitialiser">
          Réinitialiser
        </button>

        <div class="sidenav__line fr-p-0"></div>

        <fieldset class="fr-fieldset" id="radio-hint"
          aria-labelledby="radio-hint-legend radio-hint-messages fr-btn-reinitialiser">
          <legend class="fr-fieldset__legend--bold fr-fieldset__legend" id="radio-hint-legend">
            Période prédéfinie
          </legend>
          <div class="fr-fieldset__element">
            <div class="fr-radio-group">
              <input v-model="selectedPeriod" type="radio" id="radio-hint-comparison-1" name="radio-hint"
                value="current-week" @change="changeCurrentDate" />
              <label class="fr-label" for="radio-hint-comparison-1" title="Semaine courante">Semaine courante</label>
            </div>
          </div>
          <div class="fr-fieldset__element">
            <div class="fr-radio-group">
              <input v-model="selectedPeriod" type="radio" id="radio-hint-comparison-3" name="radio-hint"
                value="current-month" @change="changeCurrentDate" />
              <label class="fr-label" for="radio-hint-comparison-3" title="Mois courant">
                Mois courant</label>
            </div>
          </div>
          <div class="fr-fieldset__element">
            <div class="fr-radio-group">
              <input v-model="selectedPeriod" type="radio" id="radio-hint-comparison-5" name="radio-hint"
                value="current-year" @change="changeCurrentDate" />
              <label class="fr-label" for="radio-hint-comparison-5" title="Année courante">
                Année courante</label>
            </div>
          </div>
        </fieldset>

        <div class="sidenav__line fr-p-0"></div>

        <fieldset class="fr-fieldset" id="label-hint"
          aria-labelledby="text-label-hint-legend text-input-start-date-messages text-input-end-date-messages fr-btn-tertiary-reinitialiser">
          <legend class="fr-fieldset__legend--bold fr-fieldset__legend" id="text-label-hint-legend">
            Période personnalisée
          </legend>

          <div class="fr-fieldset__element ">
            <div class="fr-input-group">
              <span class="fr-hint-text fr-mb-2w">Format attendu : jj/mm/aaaa</span>

              <label class="fr-label fr-text--bold" for="text-input-start-date">
                Période du
              </label>
              <input class="fr-input fr-input--error" aria-describedby="text-input-date-messages"
                id="text-input-start-date" type="date" v-model="startDatePicker" @change="changeStartDate"
                title="Afficher le sélecteur de dates" />
              <p v-if="isNotRightStartDate" id="text-input-error-desc-error" class="fr-error-text">
                La date séléctionnée n'est pas disponible
              </p>
              <div class="fr-messages-group" id="text-input-start-date-messages" aria-live="assertive">
              </div>
            </div>
          </div>

          <div class="fr-fieldset__element">
            <div class="fr-input-group">
              <label class="fr-label fr-text--bold fr-mt-2w" for="text-input-end-date">
                au
              </label>
              <input class="fr-input fr-input--error" aria-describedby="text-input-date-messages"
                id="text-input-end-date" name="end-date-input" type="date" v-model="endDatePicker"
                @change="changeEndDate" title="Afficher le sélecteur de dates" />
              <p v-if="isNotRightEndDate" id="text-input-error-desc-error" class="fr-error-text">
                La date séléctionnée n'est pas valide
              </p>
              <p v-if="isNotRightPeriod" id="text-input-error-desc-error" class="fr-error-text">
                La période séléctionnée est invalide
              </p>
              <div class="fr-messages-group" id="text-input-end-date-messages" aria-live="assertive"></div>
            </div>
          </div>
        </fieldset>

        <button class="fr-btn fr-btn--tertiary fr-mt-1w fr-mb-4w fr-btn--icon-right fr-icon-close-circle-line"
          @click="resetPresetDates" title="Réinitialiser" id="fr-btn-tertiary-reinitialiser">
          Réinitialiser
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from "./SearchDropdown.vue";
import {
  endPoint,
  fetchDataFilterByForMinistries,
  fetchDataFilterByForSites,
  fetchDataFilterByForTypeSites,
  getDataApi,
  getDaysSinceFirstOfLastMonth,
  getDaysSinceFirstOfLastYear,
  getDaysSinceLastMonday,
  getQuery
} from "../services/api";
import { mapActions, mapGetters } from "vuex";
import { checkDateIsCorrect, checkPeriod, convertDate, findLabel, getPeriod } from "../utils";

export default {
  name: "Sidenav",
  components: {
    SearchBar,
  },
  data() {
    return {
      isNotRightStartDate: false,
      isNotRightEndDate: false,
      isNotRightPeriod: false
    };
  },
  computed: {
    ...mapGetters([
      'getDefaultTags',
      'getSelectedMinistry',
      'getSelectedTypeSite',
      'getSelectedSite',
      'getMinistries',
      'getTypeSites',
      'getSites',
      'getSelectedPeriod',
      'getPeriod',
      'getStartDatePicker',
      'getEndDatePicker',
    ]),
    selectedMinistry: {
      get() {
        return this.getSelectedMinistry;
      },
      set(value) {
        this.setSelectedMinistry(value);
      }
    },
    selectedTypeSite: {
      get() {
        return this.getSelectedTypeSite;
      },
      set(value) {
        this.setSelectedTypeSite(value);
      }
    },
    selectedSite: {
      get() {
        return this.getSelectedSite;
      },
      set(value) {
        this.setSelectedSite(value);
      }
    },
    ministries: {
      get() {
        return this.getMinistries;
      },
    },
    typeSites: {
      get() {
        return this.getTypeSites;
      }
    },
    sites: {
      get() {
        return this.getSites;
      }
    },
    selectedPeriod: {
      get() {
        return this.getSelectedPeriod;
      },
      set(value) {
        this.setSelectedPeriod(value);
      }
    },
    period: {
      get() {
        return this.getPeriod;
      },
      set(value) {
        this.setPeriod(value);
      }
    },
    startDatePicker: {
      get() {
        return this.getStartDatePicker;
      },
      set(value) {
        this.setStartDatePicker(value);
      }
    },
    endDatePicker: {
      get() {
        return this.getEndDatePicker;
      },
      set(value) {
        this.setEndDatePicker(value);
      }
    },
  },
  methods: {
    ...mapActions([
      'setIsSidenavVisible',
      'addTag',
      'addDefaultTag',
      'removeDefaultTag',
      'removeTag',
      'removeTagsSites',
      'setSelectedSite',
      'setSelectedMinistry',
      'setSelectedTypeSite',
      'setMinistries',
      'setTypeSites',
      'setSites',
      'setSelectedPeriod',
      'setPeriod',
      'removeTagsCurrentDate',
      'setStartDatePicker',
      'setEndDatePicker',
      'setDimensionName',
      'setIsDimensionsSegmentSelected',
      'setIsDisableDim',
      'setComparativeMinistries',
      'setComparativeTypeSites',
      'setComparativeSites',
      'setSelectionComparativeMinistry',
      'setSelectionComparativeTypeSite',
      'setSelectionComparativeSite'
    ]),
    changeMinistry(event) {
      // On affecte la valeur du selecteur
      this.selectedMinistry = event.target.value;
      if (this.selectedSite === undefined && this.selectedTypeSite === undefined) {
        this.setDimensionName("Ministère de tutelle");
      }
      this.setIsDimensionsSegmentSelected(true);
      this.setIsDisableDim(false);
      // On fetch les nouvelles requetes avec les filtres
      fetchDataFilterByForTypeSites(endPoint, this.selectedMinistry, this.selectedSite);
      fetchDataFilterByForSites(endPoint, this.selectedMinistry, this.selectedTypeSite);
      // On enlève le default tag et on ajoute le tag selon le selecteur
      this.removeDefaultTag({ label: "", role: "allMinistries" })
      this.addTag({ label: findLabel(this.ministries, this.selectedMinistry), role: "ministry" });
      // On retire le ministère selectionné de la liste de comparaison
      this.setComparativeMinistries(this.ministries.filter(ministry => ministry.value !== this.selectedMinistry));
      this.setSelectionComparativeMinistry(undefined);
      // on lance la requete globale avec la liste des sites mis à jour
      getQuery(this.selectedMinistry, this.selectedTypeSite, this.selectedSite, this.period);
    },
    changeTypeSite(event) {
      this.selectedTypeSite = event.target.value;
      if (this.selectedSite === undefined) {
        this.setDimensionName("Type de site");
      }
      this.setIsDimensionsSegmentSelected(true);
      this.setIsDisableDim(false);
      fetchDataFilterByForMinistries(endPoint, this.selectedTypeSite, this.selectedSite);
      fetchDataFilterByForSites(endPoint, this.selectedMinistry, this.selectedTypeSite);
      this.removeDefaultTag({ label: "", role: "allMinistries" });
      this.addTag({ label: findLabel(this.typeSites, this.selectedTypeSite), role: "typeSite" });
      this.setComparativeTypeSites(this.typeSites.filter(typeSite => typeSite.value !== this.selectedTypeSite));
      this.setSelectionComparativeTypeSite(undefined);
      getQuery(this.selectedMinistry, this.selectedTypeSite, this.selectedSite, this.period);
    },
    changeSite(event) {
      this.selectedSite = event.target.value;
      this.setDimensionName("Site");
      this.setIsDimensionsSegmentSelected(true);
      this.setIsDisableDim(false);
      fetchDataFilterByForMinistries(endPoint, this.selectedTypeSite, this.selectedSite);
      fetchDataFilterByForTypeSites(endPoint, this.selectedMinistry, this.selectedSite);
      this.removeDefaultTag({ label: "", role: "allMinistries" })
      this.addTag({ label: findLabel(this.sites, Number(this.selectedSite)), role: "site" });
      this.setComparativeSites(this.sites.filter(site => site.value !== this.selectedSite));
      this.setSelectionComparativeSite(undefined);
      getQuery(this.selectedMinistry, this.selectedTypeSite, this.selectedSite, this.period);
    },
    closeSidenav() {
      this.setIsSidenavVisible(false);
    },
    resetSites() {
      this.removeTagsSites();
      this.setIsDimensionsSegmentSelected(false);
      this.setIsDisableDim(true);
      getDataApi(endPoint, "dim_ministere");
      getDataApi(endPoint, "dim_type_site");
      getDataApi(endPoint, "dim_site");
      this.setComparativeMinistries(Array.from(this.ministries));
      this.setComparativeTypeSites(Array.from(this.typeSites));
      this.setComparativeSites(Array.from(this.sites));
      this.setSelectionComparativeMinistry(undefined);
      this.setSelectionComparativeTypeSite(undefined);
      this.setSelectionComparativeSite(undefined);
      this.addDefaultTag({ label: "Tous les ministères", role: "allMinistries" });
      getQuery(this.selectedMinistry, this.selectedTypeSite, this.selectedSite, this.period);
    },
    changeCurrentDate(event) {
      this.selectedPeriod = event.target.value;
      this.setIsDimensionsSegmentSelected(false);
      this.setStartDatePicker(undefined);
      this.setEndDatePicker(undefined);
      if (this.selectedPeriod.includes("current-week")) {
        this.removeDefaultTag({ label: "", role: "currentDate" });
        this.addTag({ label: "Semaine courante", role: "currentDate" });
        this.period = getDaysSinceLastMonday();
      } else if (this.selectedPeriod.includes("current-month")) {
        this.removeDefaultTag({ label: "", role: "currentDate" });
        this.addTag({ label: "Mois courant", role: "currentDate" });
        this.period = getDaysSinceFirstOfLastMonth();
      } else {
        this.removeDefaultTag({ label: "", role: "currentDate" });
        this.addTag({ label: "Année courante", role: "currentDate" });
        this.period = getDaysSinceFirstOfLastYear();
      }
      this.setSelectedPeriod(this.selectedPeriod);
      this.setPeriod(this.period);
      getQuery(this.selectedMinistry, this.selectedTypeSite, this.selectedSite, this.period);
    },
    changeStartDate(event) {
      this.startDatePicker = event.target.value;
      this.isNotRightStartDate = checkDateIsCorrect(this.startDatePicker);
      this.isNotRightPeriod = checkPeriod(this.startDatePicker, this.endDatePicker);
      if (this.endDatePicker !== undefined && !this.isNotRightStartDate && !this.isNotRightPeriod) {
        this.period = getPeriod(this.startDatePicker, this.endDatePicker);
        this.setIsDimensionsSegmentSelected(false);
        this.setDimensionName("Début");
        this.setSelectedPeriod("");
        this.setPeriod(this.period);
        this.setStartDatePicker(this.startDatePicker);
        this.setEndDatePicker(this.endDatePicker);
        this.removeDefaultTag({ label: "", role: "currentDate" });
        let label = convertDate(this.startDatePicker).replaceAll("-", "/") + " - "
          + convertDate(this.endDatePicker).replaceAll("-", "/");
        this.addTag({ label: label, role: "currentDate" });
        getQuery(this.selectedMinistry, this.selectedTypeSite, this.selectedSite, this.period, this.startDatePicker, this.endDatePicker);
      }

    },
    changeEndDate(event) {
      this.endDatePicker = event.target.value;
      this.isNotRightEndDate = checkDateIsCorrect(this.endDatePicker);
      this.isNotRightPeriod = checkPeriod(this.startDatePicker, this.endDatePicker);
      if (this.startDatePicker !== undefined && !this.isNotRightEndDate && !this.isNotRightPeriod) {
        this.setIsDimensionsSegmentSelected(false);
        this.period = getPeriod(this.startDatePicker, this.endDatePicker);
        this.setSelectedPeriod("");
        this.setPeriod(this.period);
        this.setStartDatePicker(this.startDatePicker);
        this.setEndDatePicker(this.endDatePicker);
        this.removeDefaultTag({ label: "", role: "currentDate" });
        let label = convertDate(this.startDatePicker).replaceAll("-", "/") + " - "
          + convertDate(this.endDatePicker).replaceAll("-", "/");
        this.addTag({ label: label, role: "currentDate" });
        getQuery(this.selectedMinistry, this.selectedTypeSite, this.selectedSite, this.period, this.startDatePicker, this.endDatePicker);
      }
    },
    resetPresetDates() {
      this.setStartDatePicker(undefined);
      this.setEndDatePicker(undefined);
      this.setSelectedPeriod("");
      this.setPeriod(31);
      this.removeTag({ label: "", role: "currentDate" });
      let filteredTags = this.getDefaultTags.filter(tag => tag.label === "Mois glissant");
      if (filteredTags.length === 0) {
        this.addDefaultTag({ label: "Mois glissant", role: "currentDate" });
      }
      this.isNotRightStartDate = false;
      this.isNotRightEndDate = false;
      getQuery(this.selectedMinistry, this.selectedTypeSite, this.selectedSite, this.period);
    },
  },
  mounted() {
    getDataApi(endPoint, "dim_ministere");
    getDataApi(endPoint, "dim_type_site");
    getDataApi(endPoint, "dim_site");
  },
};
</script>

<style scoped lang="scss">
@import "../styles/components/sidenav.scss";
</style>
